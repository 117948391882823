import { useState } from "react";

import Footer from "../../components/footer";
import Header from "../../components/header";
import NameSection from "../../components/nameSection";
import ButtonTwo from "../../components/buttonTwo";
import RememberOldEditions from "../../components/rememberOldEditions";
import FormData from "../../components/form";
import CardRegistration from "../../components/cardRegistration";
import CustomVideoPlayer from "../../components/video";
import ScrollingBackground from "../../components/line";
import NextEvent from "../../components/nextEvent";
import EventBanner from "../../components/eventBanner";
import DescriptionEvent from "../../components/descriptionEvent";
import WarningModal from "../../components/warning";

import {
  Container,
  ContainerAboutUs,
  ContainerCards,
  ContainerEditions,
  ContainerFamilySoccerCamp,
  ContainerRegisteredButton,
  ContainerRegistrations,
  ContentAboutUs,
  ContentEditions,
  ContentRegistrations,
  ContentTitle,
  DescriptionRegistrations,
  IconArrow,
  IconRegistrations,
  TextAboutUs,
  TextSoccerCamp,
} from "./styles";

import RegistrationsIcon from "../../assets/icons/icons_registrations.svg";

import Iconbutton from "../../assets/icons/icon_button.svg";

import ImageArrow from "../../assets/icons/arrow.svg";
import Tables from "../../components/tables";

const Home = () => {
  const [openModal, setOpenModal] = useState(false);
  const [modalWarning, setModalWarning] = useState(false);

  const handleClose = () => setOpenModal(false);

  const handleModal = () => {
    setModalWarning(false);
    setOpenModal(true);
  };

  return (
    <Container>
      <FormData openModal={openModal} handleClose={handleClose} />
      <WarningModal open={modalWarning} handleClose={handleModal} />
      <Header />

      <ContainerFamilySoccerCamp>
        <TextSoccerCamp>
          O <span style={{ color: "#F3B000" }}>Maior</span> Family Soccer Camp
          do mundo.
        </TextSoccerCamp>
        <IconArrow src={ImageArrow} alt="icone_seta" />
      </ContainerFamilySoccerCamp>

      <NextEvent />

      <ContainerAboutUs id="aboutus">
        <ContentAboutUs>
          <CustomVideoPlayer />
          <TextAboutUs>
            <NameSection
              textOne="SOBRE"
              textTwo="NÓS"
              style={{ justifyContent: "end" }}
              className="NameSection"
            />
            <label>
              Bem-vindo ao{" "}
              <span style={{ color: "#F3B000" }}>Caioba Soccer Camp!</span>
            </label>

            <p>
              "Aqui, transformamos sonhos em realidade através do futebol. Nossa
              missão é unir e fortalecer famílias, promovendo valores como
              respeito, igualdade e espírito de equipe."
            </p>
            <cite>- Caio Ribeiro</cite>
          </TextAboutUs>
        </ContentAboutUs>
      </ContainerAboutUs>

      <ContainerEditions id="timeline">
        <ContentEditions>
          <label>Reviva momentos marcantes das nossas edições passadas.</label>
          <label>
            EXPLORE NOSSA HISTÓRIA COM UMA LINHA DO TEMPO INTERATIVA
          </label>
        </ContentEditions>
        <RememberOldEditions />
      </ContainerEditions>

      <ScrollingBackground />

      <EventBanner />

      <ContainerRegistrations id="registration">
        <ContentRegistrations>
          <ContentTitle>
            <IconRegistrations src={RegistrationsIcon} alt="icon_inscricao" />
            <span>INSCRIÇÕES DO CAIOBA</span>
          </ContentTitle>

          <ContainerCards>
            <CardRegistration amount="1 INSCRIÇÃO" amountRegistration={1} />
            <CardRegistration amount="2 INSCRIÇÕES" amountRegistration={2} />
            <CardRegistration amount="3 INSCRIÇÕES" amountRegistration={3} />
          </ContainerCards>

          <DescriptionRegistrations>
            <span>*</span>O desconto para segunda e terceira inscrições é válido
            <span> somente para irmãos</span>. <br /> <span>**</span>Descontos
            não acumulativos.
          </DescriptionRegistrations>

          <ContainerRegisteredButton>
            <div>
              <ButtonTwo
                iconImage={Iconbutton}
                icon={true}
                width="100%"
                onClick={() => setModalWarning(true)}
                className="buttonTwo"
              >
                INSCREVA-SE AGORA
              </ButtonTwo>
            </div>
          </ContainerRegisteredButton>

          <DescriptionRegistrations style={{ marginBottom: 0 }}>
            <span>*</span>Para participar, é necessário fazer as{" "}
            <span>reservas de hotel</span>, e inscrição do Caioba.
          </DescriptionRegistrations>

          <DescriptionRegistrations style={{ fontWeight: 300 }}>
            <span>**</span>Para que a inscrição seja validada a reserva da
            hospedagem precisa ser feita diretamente com o Caioba ou com alguma
            das agências parceiras. Consulte agências parceiras com nosso
            atendimento no WhatsApp.
          </DescriptionRegistrations>
        </ContentRegistrations>
      </ContainerRegistrations>

      <DescriptionEvent />

      <Tables />

      <Footer />
    </Container>
  );
};

export default Home;
