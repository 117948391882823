import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "./home";
import BeachTennisFemale from "./beachTennisFemale";
import BeachTennisMale from "./beachTennisMale";
import MothersGame from "./mother'sGame";
import FathersGame from "./father'sGame";
import Campaign from "./campaign";
import TennisClinic from "./tennisClinic";

const Root = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/beach-tennis-feminino" element={<BeachTennisFemale />} />
        <Route path="/beach-tennis-masculino" element={<BeachTennisMale />} />
        <Route path="/clinica-de-tenis" element={<TennisClinic />} />
        <Route path="/jogo-das-maes" element={<MothersGame />} />
        <Route path="/jogo-dos-pais" element={<FathersGame />} />
        <Route path="/campaign" element={<Campaign />} />
      </Routes>
    </Router>
  );
};

export default Root;

/*
<Route path="/desafio-pai-e-filho" element={<FatherSonChallenge />} />
<Route path="/treino-evoluir" element={<TrainingEvolves />} />
<Route path="/curso-melitta" element={<MelittaCourse />} />
<Route path="/boa-vista/:hash" element={<BoaVista />} />
<Route path="/yoga" element={<Yoga />} />
*/
