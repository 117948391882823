import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import Swal from "sweetalert2";

import LoadingForm from "../../components/loadingForm";
import MaskedTextField from "../../components/maskedField";
import Button from "../../components/button";

import { TextField } from "@mui/material";

import {
  Container,
  ContainerButton,
  ContainerNameEvent,
  Content,
  DescriptionEvent,
  Form,
  Informations,
  LogoEvent,
  RacketImage,
  TextOne,
  TextTwo,
} from "./styles";

import Logo from "../../assets/landing_pages/logo.svg";
import Soccer from "../../assets/landing_pages/soccer.svg";

const FathersGame = () => {
  const { control, handleSubmit, register } = useForm();

  const [loading, setLoading] = useState(false);

  const checkLength = (value, length) => {
    return value.replace(/[^0-9]/g, "").length === length;
  };

  const onSubmit = async (formData) => {
    setLoading(true);

    try {
      const response = await fetch(
        "https://api.caiobasc.com.br/landing-page/register",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...formData,
            eventName: "Jogo dos Pais",
          }),
        }
      );

      const data = await response.json();

      if (data.success === false) {
        setLoading(false);
        Swal.fire({
          title: "Erro ao enviar formulário",
          html: data.message,
          icon: "error",
        }).then(() => {
          window.location.reload();
        });

        return;
      }

      setLoading(false);

      Swal.fire({
        title: "Formulário enviado com sucesso!",

        icon: "success",
      }).then(() => {
        window.location.reload();
      });
    } catch (error) {
      setLoading(false);
      Swal.fire({
        title: `Erro ao enviar formulário: ${error}`,
        html: "Não foi possível enviar o formulário",
        icon: "error",
      }).then(() => {
        window.location.reload();
      });
    }
  };

  return (
    <Container>
      {loading && <LoadingForm />}

      <Content>
        <ContainerNameEvent>
          <LogoEvent alt="logo" src={Logo} />
          <DescriptionEvent>
            <div>
              <h2>Inscrição para o</h2>
              <h1>JOGO DOS PAIS</h1>
            </div>
            <RacketImage alt="raquete" src={Soccer} />
          </DescriptionEvent>

          <Informations>
            <TextOne>
              É HORA DE MOSTRAR O TALENTO QUE OS NOSSOS CRAQUES HERDAREM!
            </TextOne>
            <TextTwo>Mostre para seu filho como é gostoso competir!</TextTwo>
          </Informations>
        </ContainerNameEvent>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            {...register("name")}
            required
            label="Nome do Jogador"
            variant="standard"
            fullWidth
            sx={{ mb: 2 }}
          />

          <TextField
            {...register("email")}
            label="Email"
            required
            variant="standard"
            fullWidth
            sx={{ mb: 2 }}
          />

          <Controller
            control={control}
            name="phone"
            rules={{
              validate: (value) =>
                checkLength(value, 11) || "Telefone incompleto",
            }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <MaskedTextField
                mask="(99) 99999-9999"
                label="Telefone"
                value={value}
                variant="standard"
                onChange={onChange}
                fullWidth
                sx={{ mb: 2 }}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
          />

          <ContainerButton>
            <Button backgroundColor="#232B60" type="submit" width="100%">
              Confirmar
            </Button>
          </ContainerButton>
        </Form>
      </Content>
    </Container>
  );
};

export default FathersGame;
